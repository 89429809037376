import { default as _91_46_46_46slug_93TiTRYnnSVPMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/[...slug].vue?macro=true";
import { default as _91slug_93EMHMvMwDBXMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/[slug].vue?macro=true";
import { default as indexJNRkvrnY1bMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/index.vue?macro=true";
import { default as _91slug_93qLi7Mz023HMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/[slug].vue?macro=true";
import { default as indexyYUioMLRuuMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/index.vue?macro=true";
import { default as index4CPt2amHY9Meta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/index.vue?macro=true";
import { default as medewerkersC9jFReX8JzMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/medewerkers.vue?macro=true";
import { default as _91slug_93KM046fk3IgMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/[slug].vue?macro=true";
import { default as indexB9JOVhX14XMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/index.vue?macro=true";
import { default as _91slug_936oRnjlTnV8Meta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/[slug].vue?macro=true";
import { default as indextO3oOWfQHqMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/index.vue?macro=true";
import { default as _91slug_93hGr1WNDWF5Meta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/[slug].vue?macro=true";
import { default as indexmUBhVoxW8GMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/index.vue?macro=true";
import { default as indexliNWjn73f9Meta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/index.vue?macro=true";
import { default as contactw1I2pM3NVOMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/contact.vue?macro=true";
import { default as eventsmzb51YJiBWMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/events.vue?macro=true";
import { default as index7OIgUsqPdLMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/index.vue?macro=true";
import { default as _91slug_93elA1NaufniMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/[slug].vue?macro=true";
import { default as indexoDKRv4ku2gMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/index.vue?macro=true";
import { default as _91slug_932jpPVcprdBMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/[slug].vue?macro=true";
import { default as indexO5fljcmaIiMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/index.vue?macro=true";
import { default as search1gvnrmdkW0Meta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/search.vue?macro=true";
import { default as _91slug_93NKcs8HqdOJMeta } from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/vacancies/[slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93TiTRYnnSVPMeta?.name ?? "slug___nl___default",
    path: _91_46_46_46slug_93TiTRYnnSVPMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93TiTRYnnSVPMeta || {},
    alias: _91_46_46_46slug_93TiTRYnnSVPMeta?.alias || [],
    redirect: _91_46_46_46slug_93TiTRYnnSVPMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TiTRYnnSVPMeta?.name ?? "slug___nl",
    path: _91_46_46_46slug_93TiTRYnnSVPMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93TiTRYnnSVPMeta || {},
    alias: _91_46_46_46slug_93TiTRYnnSVPMeta?.alias || [],
    redirect: _91_46_46_46slug_93TiTRYnnSVPMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TiTRYnnSVPMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93TiTRYnnSVPMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93TiTRYnnSVPMeta || {},
    alias: _91_46_46_46slug_93TiTRYnnSVPMeta?.alias || [],
    redirect: _91_46_46_46slug_93TiTRYnnSVPMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TiTRYnnSVPMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93TiTRYnnSVPMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93TiTRYnnSVPMeta || {},
    alias: _91_46_46_46slug_93TiTRYnnSVPMeta?.alias || [],
    redirect: _91_46_46_46slug_93TiTRYnnSVPMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EMHMvMwDBXMeta?.name ?? "about-us-business-councils-slug___nl___default",
    path: _91slug_93EMHMvMwDBXMeta?.path ?? "/over-ons/business-councils/:slug()",
    meta: _91slug_93EMHMvMwDBXMeta || {},
    alias: _91slug_93EMHMvMwDBXMeta?.alias || [],
    redirect: _91slug_93EMHMvMwDBXMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EMHMvMwDBXMeta?.name ?? "about-us-business-councils-slug___nl",
    path: _91slug_93EMHMvMwDBXMeta?.path ?? "/over-ons/business-councils/:slug()",
    meta: _91slug_93EMHMvMwDBXMeta || {},
    alias: _91slug_93EMHMvMwDBXMeta?.alias || [],
    redirect: _91slug_93EMHMvMwDBXMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EMHMvMwDBXMeta?.name ?? "about-us-business-councils-slug___en",
    path: _91slug_93EMHMvMwDBXMeta?.path ?? "/about-us/business-councils/:slug()",
    meta: _91slug_93EMHMvMwDBXMeta || {},
    alias: _91slug_93EMHMvMwDBXMeta?.alias || [],
    redirect: _91slug_93EMHMvMwDBXMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EMHMvMwDBXMeta?.name ?? "about-us-business-councils-slug___de",
    path: _91slug_93EMHMvMwDBXMeta?.path ?? "/uber-uns/unternehmensrate/:slug()",
    meta: _91slug_93EMHMvMwDBXMeta || {},
    alias: _91slug_93EMHMvMwDBXMeta?.alias || [],
    redirect: _91slug_93EMHMvMwDBXMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexJNRkvrnY1bMeta?.name ?? "about-us-business-councils___nl___default",
    path: indexJNRkvrnY1bMeta?.path ?? "/over-ons/business-councils",
    meta: indexJNRkvrnY1bMeta || {},
    alias: indexJNRkvrnY1bMeta?.alias || [],
    redirect: indexJNRkvrnY1bMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/index.vue").then(m => m.default || m)
  },
  {
    name: indexJNRkvrnY1bMeta?.name ?? "about-us-business-councils___nl",
    path: indexJNRkvrnY1bMeta?.path ?? "/over-ons/business-councils",
    meta: indexJNRkvrnY1bMeta || {},
    alias: indexJNRkvrnY1bMeta?.alias || [],
    redirect: indexJNRkvrnY1bMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/index.vue").then(m => m.default || m)
  },
  {
    name: indexJNRkvrnY1bMeta?.name ?? "about-us-business-councils___en",
    path: indexJNRkvrnY1bMeta?.path ?? "/about-us/business-councils",
    meta: indexJNRkvrnY1bMeta || {},
    alias: indexJNRkvrnY1bMeta?.alias || [],
    redirect: indexJNRkvrnY1bMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/index.vue").then(m => m.default || m)
  },
  {
    name: indexJNRkvrnY1bMeta?.name ?? "about-us-business-councils___de",
    path: indexJNRkvrnY1bMeta?.path ?? "/uber-uns/unternehmensrate",
    meta: indexJNRkvrnY1bMeta || {},
    alias: indexJNRkvrnY1bMeta?.alias || [],
    redirect: indexJNRkvrnY1bMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/business-councils/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qLi7Mz023HMeta?.name ?? "about-us-committees-slug___nl___default",
    path: _91slug_93qLi7Mz023HMeta?.path ?? "/over-ons/comites/:slug()",
    meta: _91slug_93qLi7Mz023HMeta || {},
    alias: _91slug_93qLi7Mz023HMeta?.alias || [],
    redirect: _91slug_93qLi7Mz023HMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qLi7Mz023HMeta?.name ?? "about-us-committees-slug___nl",
    path: _91slug_93qLi7Mz023HMeta?.path ?? "/over-ons/comites/:slug()",
    meta: _91slug_93qLi7Mz023HMeta || {},
    alias: _91slug_93qLi7Mz023HMeta?.alias || [],
    redirect: _91slug_93qLi7Mz023HMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qLi7Mz023HMeta?.name ?? "about-us-committees-slug___en",
    path: _91slug_93qLi7Mz023HMeta?.path ?? "/about-us/committees/:slug()",
    meta: _91slug_93qLi7Mz023HMeta || {},
    alias: _91slug_93qLi7Mz023HMeta?.alias || [],
    redirect: _91slug_93qLi7Mz023HMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qLi7Mz023HMeta?.name ?? "about-us-committees-slug___de",
    path: _91slug_93qLi7Mz023HMeta?.path ?? "/uber-uns/aussch%C3%BCsse/:slug()",
    meta: _91slug_93qLi7Mz023HMeta || {},
    alias: _91slug_93qLi7Mz023HMeta?.alias || [],
    redirect: _91slug_93qLi7Mz023HMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyYUioMLRuuMeta?.name ?? "about-us-committees___nl___default",
    path: indexyYUioMLRuuMeta?.path ?? "/over-ons/comites",
    meta: indexyYUioMLRuuMeta || {},
    alias: indexyYUioMLRuuMeta?.alias || [],
    redirect: indexyYUioMLRuuMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/index.vue").then(m => m.default || m)
  },
  {
    name: indexyYUioMLRuuMeta?.name ?? "about-us-committees___nl",
    path: indexyYUioMLRuuMeta?.path ?? "/over-ons/comites",
    meta: indexyYUioMLRuuMeta || {},
    alias: indexyYUioMLRuuMeta?.alias || [],
    redirect: indexyYUioMLRuuMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/index.vue").then(m => m.default || m)
  },
  {
    name: indexyYUioMLRuuMeta?.name ?? "about-us-committees___en",
    path: indexyYUioMLRuuMeta?.path ?? "/about-us/committees",
    meta: indexyYUioMLRuuMeta || {},
    alias: indexyYUioMLRuuMeta?.alias || [],
    redirect: indexyYUioMLRuuMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/index.vue").then(m => m.default || m)
  },
  {
    name: indexyYUioMLRuuMeta?.name ?? "about-us-committees___de",
    path: indexyYUioMLRuuMeta?.path ?? "/uber-uns/aussch%C3%BCsse",
    meta: indexyYUioMLRuuMeta || {},
    alias: indexyYUioMLRuuMeta?.alias || [],
    redirect: indexyYUioMLRuuMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/committees/index.vue").then(m => m.default || m)
  },
  {
    name: index4CPt2amHY9Meta?.name ?? "about-us___nl___default",
    path: index4CPt2amHY9Meta?.path ?? "/over-ons",
    meta: index4CPt2amHY9Meta || {},
    alias: index4CPt2amHY9Meta?.alias || [],
    redirect: index4CPt2amHY9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index4CPt2amHY9Meta?.name ?? "about-us___nl",
    path: index4CPt2amHY9Meta?.path ?? "/over-ons",
    meta: index4CPt2amHY9Meta || {},
    alias: index4CPt2amHY9Meta?.alias || [],
    redirect: index4CPt2amHY9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index4CPt2amHY9Meta?.name ?? "about-us___en",
    path: index4CPt2amHY9Meta?.path ?? "/about-us",
    meta: index4CPt2amHY9Meta || {},
    alias: index4CPt2amHY9Meta?.alias || [],
    redirect: index4CPt2amHY9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index4CPt2amHY9Meta?.name ?? "about-us___de",
    path: index4CPt2amHY9Meta?.path ?? "/uber-uns",
    meta: index4CPt2amHY9Meta || {},
    alias: index4CPt2amHY9Meta?.alias || [],
    redirect: index4CPt2amHY9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: medewerkersC9jFReX8JzMeta?.name ?? "about-us-medewerkers___nl___default",
    path: medewerkersC9jFReX8JzMeta?.path ?? "/over-ons/medewerkers",
    meta: medewerkersC9jFReX8JzMeta || {},
    alias: medewerkersC9jFReX8JzMeta?.alias || [],
    redirect: medewerkersC9jFReX8JzMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: medewerkersC9jFReX8JzMeta?.name ?? "about-us-medewerkers___nl",
    path: medewerkersC9jFReX8JzMeta?.path ?? "/over-ons/medewerkers",
    meta: medewerkersC9jFReX8JzMeta || {},
    alias: medewerkersC9jFReX8JzMeta?.alias || [],
    redirect: medewerkersC9jFReX8JzMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: medewerkersC9jFReX8JzMeta?.name ?? "about-us-medewerkers___en",
    path: medewerkersC9jFReX8JzMeta?.path ?? "/about-us/employees",
    meta: medewerkersC9jFReX8JzMeta || {},
    alias: medewerkersC9jFReX8JzMeta?.alias || [],
    redirect: medewerkersC9jFReX8JzMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: medewerkersC9jFReX8JzMeta?.name ?? "about-us-medewerkers___de",
    path: medewerkersC9jFReX8JzMeta?.path ?? "/uber-uns/personal",
    meta: medewerkersC9jFReX8JzMeta || {},
    alias: medewerkersC9jFReX8JzMeta?.alias || [],
    redirect: medewerkersC9jFReX8JzMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KM046fk3IgMeta?.name ?? "about-us-networking-clubs-slug___nl___default",
    path: _91slug_93KM046fk3IgMeta?.path ?? "/over-ons/netwerkclubs/:slug()",
    meta: _91slug_93KM046fk3IgMeta || {},
    alias: _91slug_93KM046fk3IgMeta?.alias || [],
    redirect: _91slug_93KM046fk3IgMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KM046fk3IgMeta?.name ?? "about-us-networking-clubs-slug___nl",
    path: _91slug_93KM046fk3IgMeta?.path ?? "/over-ons/netwerkclubs/:slug()",
    meta: _91slug_93KM046fk3IgMeta || {},
    alias: _91slug_93KM046fk3IgMeta?.alias || [],
    redirect: _91slug_93KM046fk3IgMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KM046fk3IgMeta?.name ?? "about-us-networking-clubs-slug___en",
    path: _91slug_93KM046fk3IgMeta?.path ?? "/about-us/networking-clubs/:slug()",
    meta: _91slug_93KM046fk3IgMeta || {},
    alias: _91slug_93KM046fk3IgMeta?.alias || [],
    redirect: _91slug_93KM046fk3IgMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KM046fk3IgMeta?.name ?? "about-us-networking-clubs-slug___de",
    path: _91slug_93KM046fk3IgMeta?.path ?? "/uber-uns/networking-clubs/:slug()",
    meta: _91slug_93KM046fk3IgMeta || {},
    alias: _91slug_93KM046fk3IgMeta?.alias || [],
    redirect: _91slug_93KM046fk3IgMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexB9JOVhX14XMeta?.name ?? "about-us-networking-clubs___nl___default",
    path: indexB9JOVhX14XMeta?.path ?? "/over-ons/netwerkclubs",
    meta: indexB9JOVhX14XMeta || {},
    alias: indexB9JOVhX14XMeta?.alias || [],
    redirect: indexB9JOVhX14XMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/index.vue").then(m => m.default || m)
  },
  {
    name: indexB9JOVhX14XMeta?.name ?? "about-us-networking-clubs___nl",
    path: indexB9JOVhX14XMeta?.path ?? "/over-ons/netwerkclubs",
    meta: indexB9JOVhX14XMeta || {},
    alias: indexB9JOVhX14XMeta?.alias || [],
    redirect: indexB9JOVhX14XMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/index.vue").then(m => m.default || m)
  },
  {
    name: indexB9JOVhX14XMeta?.name ?? "about-us-networking-clubs___en",
    path: indexB9JOVhX14XMeta?.path ?? "/about-us/networking-clubs",
    meta: indexB9JOVhX14XMeta || {},
    alias: indexB9JOVhX14XMeta?.alias || [],
    redirect: indexB9JOVhX14XMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/index.vue").then(m => m.default || m)
  },
  {
    name: indexB9JOVhX14XMeta?.name ?? "about-us-networking-clubs___de",
    path: indexB9JOVhX14XMeta?.path ?? "/uber-uns/networking-clubs",
    meta: indexB9JOVhX14XMeta || {},
    alias: indexB9JOVhX14XMeta?.alias || [],
    redirect: indexB9JOVhX14XMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-clubs/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936oRnjlTnV8Meta?.name ?? "about-us-networking-groups-slug___nl___default",
    path: _91slug_936oRnjlTnV8Meta?.path ?? "/over-ons/netwerkgroepen/:slug()",
    meta: _91slug_936oRnjlTnV8Meta || {},
    alias: _91slug_936oRnjlTnV8Meta?.alias || [],
    redirect: _91slug_936oRnjlTnV8Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936oRnjlTnV8Meta?.name ?? "about-us-networking-groups-slug___nl",
    path: _91slug_936oRnjlTnV8Meta?.path ?? "/over-ons/netwerkgroepen/:slug()",
    meta: _91slug_936oRnjlTnV8Meta || {},
    alias: _91slug_936oRnjlTnV8Meta?.alias || [],
    redirect: _91slug_936oRnjlTnV8Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936oRnjlTnV8Meta?.name ?? "about-us-networking-groups-slug___en",
    path: _91slug_936oRnjlTnV8Meta?.path ?? "/about-us/networking-groups/:slug()",
    meta: _91slug_936oRnjlTnV8Meta || {},
    alias: _91slug_936oRnjlTnV8Meta?.alias || [],
    redirect: _91slug_936oRnjlTnV8Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936oRnjlTnV8Meta?.name ?? "about-us-networking-groups-slug___de",
    path: _91slug_936oRnjlTnV8Meta?.path ?? "/uber-uns/netzwerkgruppen/:slug()",
    meta: _91slug_936oRnjlTnV8Meta || {},
    alias: _91slug_936oRnjlTnV8Meta?.alias || [],
    redirect: _91slug_936oRnjlTnV8Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/[slug].vue").then(m => m.default || m)
  },
  {
    name: indextO3oOWfQHqMeta?.name ?? "about-us-networking-groups___nl___default",
    path: indextO3oOWfQHqMeta?.path ?? "/over-ons/netwerkgroepen",
    meta: indextO3oOWfQHqMeta || {},
    alias: indextO3oOWfQHqMeta?.alias || [],
    redirect: indextO3oOWfQHqMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indextO3oOWfQHqMeta?.name ?? "about-us-networking-groups___nl",
    path: indextO3oOWfQHqMeta?.path ?? "/over-ons/netwerkgroepen",
    meta: indextO3oOWfQHqMeta || {},
    alias: indextO3oOWfQHqMeta?.alias || [],
    redirect: indextO3oOWfQHqMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indextO3oOWfQHqMeta?.name ?? "about-us-networking-groups___en",
    path: indextO3oOWfQHqMeta?.path ?? "/about-us/networking-groups",
    meta: indextO3oOWfQHqMeta || {},
    alias: indextO3oOWfQHqMeta?.alias || [],
    redirect: indextO3oOWfQHqMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indextO3oOWfQHqMeta?.name ?? "about-us-networking-groups___de",
    path: indextO3oOWfQHqMeta?.path ?? "/uber-uns/netzwerkgruppen",
    meta: indextO3oOWfQHqMeta || {},
    alias: indextO3oOWfQHqMeta?.alias || [],
    redirect: indextO3oOWfQHqMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/about-us/networking-groups/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hGr1WNDWF5Meta?.name ?? "ambitions-ambition-slug___nl___default",
    path: _91slug_93hGr1WNDWF5Meta?.path ?? "/ambities/:ambition()/:slug()",
    meta: _91slug_93hGr1WNDWF5Meta || {},
    alias: _91slug_93hGr1WNDWF5Meta?.alias || [],
    redirect: _91slug_93hGr1WNDWF5Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hGr1WNDWF5Meta?.name ?? "ambitions-ambition-slug___nl",
    path: _91slug_93hGr1WNDWF5Meta?.path ?? "/ambities/:ambition()/:slug()",
    meta: _91slug_93hGr1WNDWF5Meta || {},
    alias: _91slug_93hGr1WNDWF5Meta?.alias || [],
    redirect: _91slug_93hGr1WNDWF5Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hGr1WNDWF5Meta?.name ?? "ambitions-ambition-slug___en",
    path: _91slug_93hGr1WNDWF5Meta?.path ?? "/ambitions/:ambition()/:slug()",
    meta: _91slug_93hGr1WNDWF5Meta || {},
    alias: _91slug_93hGr1WNDWF5Meta?.alias || [],
    redirect: _91slug_93hGr1WNDWF5Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hGr1WNDWF5Meta?.name ?? "ambitions-ambition-slug___de",
    path: _91slug_93hGr1WNDWF5Meta?.path ?? "/ambitionen/:ambition()/:slug()",
    meta: _91slug_93hGr1WNDWF5Meta || {},
    alias: _91slug_93hGr1WNDWF5Meta?.alias || [],
    redirect: _91slug_93hGr1WNDWF5Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmUBhVoxW8GMeta?.name ?? "ambitions-ambition___nl___default",
    path: indexmUBhVoxW8GMeta?.path ?? "/ambities/:ambition()",
    meta: indexmUBhVoxW8GMeta || {},
    alias: indexmUBhVoxW8GMeta?.alias || [],
    redirect: indexmUBhVoxW8GMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmUBhVoxW8GMeta?.name ?? "ambitions-ambition___nl",
    path: indexmUBhVoxW8GMeta?.path ?? "/ambities/:ambition()",
    meta: indexmUBhVoxW8GMeta || {},
    alias: indexmUBhVoxW8GMeta?.alias || [],
    redirect: indexmUBhVoxW8GMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmUBhVoxW8GMeta?.name ?? "ambitions-ambition___en",
    path: indexmUBhVoxW8GMeta?.path ?? "/ambitions/:ambition()",
    meta: indexmUBhVoxW8GMeta || {},
    alias: indexmUBhVoxW8GMeta?.alias || [],
    redirect: indexmUBhVoxW8GMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmUBhVoxW8GMeta?.name ?? "ambitions-ambition___de",
    path: indexmUBhVoxW8GMeta?.path ?? "/ambitionen/:ambition()",
    meta: indexmUBhVoxW8GMeta || {},
    alias: indexmUBhVoxW8GMeta?.alias || [],
    redirect: indexmUBhVoxW8GMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/[ambition]/index.vue").then(m => m.default || m)
  },
  {
    name: indexliNWjn73f9Meta?.name ?? "ambitions___nl___default",
    path: indexliNWjn73f9Meta?.path ?? "/ambities",
    meta: indexliNWjn73f9Meta || {},
    alias: indexliNWjn73f9Meta?.alias || [],
    redirect: indexliNWjn73f9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/index.vue").then(m => m.default || m)
  },
  {
    name: indexliNWjn73f9Meta?.name ?? "ambitions___nl",
    path: indexliNWjn73f9Meta?.path ?? "/ambities",
    meta: indexliNWjn73f9Meta || {},
    alias: indexliNWjn73f9Meta?.alias || [],
    redirect: indexliNWjn73f9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/index.vue").then(m => m.default || m)
  },
  {
    name: indexliNWjn73f9Meta?.name ?? "ambitions___en",
    path: indexliNWjn73f9Meta?.path ?? "/ambitions",
    meta: indexliNWjn73f9Meta || {},
    alias: indexliNWjn73f9Meta?.alias || [],
    redirect: indexliNWjn73f9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/index.vue").then(m => m.default || m)
  },
  {
    name: indexliNWjn73f9Meta?.name ?? "ambitions___de",
    path: indexliNWjn73f9Meta?.path ?? "/ambitionen",
    meta: indexliNWjn73f9Meta || {},
    alias: indexliNWjn73f9Meta?.alias || [],
    redirect: indexliNWjn73f9Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/ambitions/index.vue").then(m => m.default || m)
  },
  {
    name: contactw1I2pM3NVOMeta?.name ?? "contact___nl___default",
    path: contactw1I2pM3NVOMeta?.path ?? "/contact",
    meta: contactw1I2pM3NVOMeta || {},
    alias: contactw1I2pM3NVOMeta?.alias || [],
    redirect: contactw1I2pM3NVOMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactw1I2pM3NVOMeta?.name ?? "contact___nl",
    path: contactw1I2pM3NVOMeta?.path ?? "/contact",
    meta: contactw1I2pM3NVOMeta || {},
    alias: contactw1I2pM3NVOMeta?.alias || [],
    redirect: contactw1I2pM3NVOMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactw1I2pM3NVOMeta?.name ?? "contact___en",
    path: contactw1I2pM3NVOMeta?.path ?? "/contact",
    meta: contactw1I2pM3NVOMeta || {},
    alias: contactw1I2pM3NVOMeta?.alias || [],
    redirect: contactw1I2pM3NVOMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactw1I2pM3NVOMeta?.name ?? "contact___de",
    path: contactw1I2pM3NVOMeta?.path ?? "/kontakt",
    meta: contactw1I2pM3NVOMeta || {},
    alias: contactw1I2pM3NVOMeta?.alias || [],
    redirect: contactw1I2pM3NVOMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: eventsmzb51YJiBWMeta?.name ?? "events___nl___default",
    path: eventsmzb51YJiBWMeta?.path ?? "/agenda",
    meta: eventsmzb51YJiBWMeta || {},
    alias: eventsmzb51YJiBWMeta?.alias || [],
    redirect: eventsmzb51YJiBWMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/events.vue").then(m => m.default || m)
  },
  {
    name: eventsmzb51YJiBWMeta?.name ?? "events___nl",
    path: eventsmzb51YJiBWMeta?.path ?? "/agenda",
    meta: eventsmzb51YJiBWMeta || {},
    alias: eventsmzb51YJiBWMeta?.alias || [],
    redirect: eventsmzb51YJiBWMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/events.vue").then(m => m.default || m)
  },
  {
    name: eventsmzb51YJiBWMeta?.name ?? "events___en",
    path: eventsmzb51YJiBWMeta?.path ?? "/events",
    meta: eventsmzb51YJiBWMeta || {},
    alias: eventsmzb51YJiBWMeta?.alias || [],
    redirect: eventsmzb51YJiBWMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/events.vue").then(m => m.default || m)
  },
  {
    name: eventsmzb51YJiBWMeta?.name ?? "events___de",
    path: eventsmzb51YJiBWMeta?.path ?? "/veranstaltungen",
    meta: eventsmzb51YJiBWMeta || {},
    alias: eventsmzb51YJiBWMeta?.alias || [],
    redirect: eventsmzb51YJiBWMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/events.vue").then(m => m.default || m)
  },
  {
    name: index7OIgUsqPdLMeta?.name ?? "index___nl___default",
    path: index7OIgUsqPdLMeta?.path ?? "/",
    meta: index7OIgUsqPdLMeta || {},
    alias: index7OIgUsqPdLMeta?.alias || [],
    redirect: index7OIgUsqPdLMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7OIgUsqPdLMeta?.name ?? "index___nl",
    path: index7OIgUsqPdLMeta?.path ?? "/",
    meta: index7OIgUsqPdLMeta || {},
    alias: index7OIgUsqPdLMeta?.alias || [],
    redirect: index7OIgUsqPdLMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7OIgUsqPdLMeta?.name ?? "index___en",
    path: index7OIgUsqPdLMeta?.path ?? "/",
    meta: index7OIgUsqPdLMeta || {},
    alias: index7OIgUsqPdLMeta?.alias || [],
    redirect: index7OIgUsqPdLMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7OIgUsqPdLMeta?.name ?? "index___de",
    path: index7OIgUsqPdLMeta?.path ?? "/",
    meta: index7OIgUsqPdLMeta || {},
    alias: index7OIgUsqPdLMeta?.alias || [],
    redirect: index7OIgUsqPdLMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93elA1NaufniMeta?.name ?? "members-slug___nl___default",
    path: _91slug_93elA1NaufniMeta?.path ?? "/leden/:slug()",
    meta: _91slug_93elA1NaufniMeta || {},
    alias: _91slug_93elA1NaufniMeta?.alias || [],
    redirect: _91slug_93elA1NaufniMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93elA1NaufniMeta?.name ?? "members-slug___nl",
    path: _91slug_93elA1NaufniMeta?.path ?? "/leden/:slug()",
    meta: _91slug_93elA1NaufniMeta || {},
    alias: _91slug_93elA1NaufniMeta?.alias || [],
    redirect: _91slug_93elA1NaufniMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93elA1NaufniMeta?.name ?? "members-slug___en",
    path: _91slug_93elA1NaufniMeta?.path ?? "/members/:slug()",
    meta: _91slug_93elA1NaufniMeta || {},
    alias: _91slug_93elA1NaufniMeta?.alias || [],
    redirect: _91slug_93elA1NaufniMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93elA1NaufniMeta?.name ?? "members-slug___de",
    path: _91slug_93elA1NaufniMeta?.path ?? "/mitglieder/:slug()",
    meta: _91slug_93elA1NaufniMeta || {},
    alias: _91slug_93elA1NaufniMeta?.alias || [],
    redirect: _91slug_93elA1NaufniMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexoDKRv4ku2gMeta?.name ?? "members___nl___default",
    path: indexoDKRv4ku2gMeta?.path ?? "/leden",
    meta: indexoDKRv4ku2gMeta || {},
    alias: indexoDKRv4ku2gMeta?.alias || [],
    redirect: indexoDKRv4ku2gMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDKRv4ku2gMeta?.name ?? "members___nl",
    path: indexoDKRv4ku2gMeta?.path ?? "/leden",
    meta: indexoDKRv4ku2gMeta || {},
    alias: indexoDKRv4ku2gMeta?.alias || [],
    redirect: indexoDKRv4ku2gMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDKRv4ku2gMeta?.name ?? "members___en",
    path: indexoDKRv4ku2gMeta?.path ?? "/members",
    meta: indexoDKRv4ku2gMeta || {},
    alias: indexoDKRv4ku2gMeta?.alias || [],
    redirect: indexoDKRv4ku2gMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDKRv4ku2gMeta?.name ?? "members___de",
    path: indexoDKRv4ku2gMeta?.path ?? "/mitglieder",
    meta: indexoDKRv4ku2gMeta || {},
    alias: indexoDKRv4ku2gMeta?.alias || [],
    redirect: indexoDKRv4ku2gMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932jpPVcprdBMeta?.name ?? "news-slug___nl___default",
    path: _91slug_932jpPVcprdBMeta?.path ?? "/nieuws/:slug()",
    meta: _91slug_932jpPVcprdBMeta || {},
    alias: _91slug_932jpPVcprdBMeta?.alias || [],
    redirect: _91slug_932jpPVcprdBMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932jpPVcprdBMeta?.name ?? "news-slug___nl",
    path: _91slug_932jpPVcprdBMeta?.path ?? "/nieuws/:slug()",
    meta: _91slug_932jpPVcprdBMeta || {},
    alias: _91slug_932jpPVcprdBMeta?.alias || [],
    redirect: _91slug_932jpPVcprdBMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932jpPVcprdBMeta?.name ?? "news-slug___en",
    path: _91slug_932jpPVcprdBMeta?.path ?? "/news/:slug()",
    meta: _91slug_932jpPVcprdBMeta || {},
    alias: _91slug_932jpPVcprdBMeta?.alias || [],
    redirect: _91slug_932jpPVcprdBMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932jpPVcprdBMeta?.name ?? "news-slug___de",
    path: _91slug_932jpPVcprdBMeta?.path ?? "/nachrichten/:slug()",
    meta: _91slug_932jpPVcprdBMeta || {},
    alias: _91slug_932jpPVcprdBMeta?.alias || [],
    redirect: _91slug_932jpPVcprdBMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexO5fljcmaIiMeta?.name ?? "news___nl___default",
    path: indexO5fljcmaIiMeta?.path ?? "/nieuws",
    meta: indexO5fljcmaIiMeta || {},
    alias: indexO5fljcmaIiMeta?.alias || [],
    redirect: indexO5fljcmaIiMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexO5fljcmaIiMeta?.name ?? "news___nl",
    path: indexO5fljcmaIiMeta?.path ?? "/nieuws",
    meta: indexO5fljcmaIiMeta || {},
    alias: indexO5fljcmaIiMeta?.alias || [],
    redirect: indexO5fljcmaIiMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexO5fljcmaIiMeta?.name ?? "news___en",
    path: indexO5fljcmaIiMeta?.path ?? "/news",
    meta: indexO5fljcmaIiMeta || {},
    alias: indexO5fljcmaIiMeta?.alias || [],
    redirect: indexO5fljcmaIiMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexO5fljcmaIiMeta?.name ?? "news___de",
    path: indexO5fljcmaIiMeta?.path ?? "/nachrichten",
    meta: indexO5fljcmaIiMeta || {},
    alias: indexO5fljcmaIiMeta?.alias || [],
    redirect: indexO5fljcmaIiMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: search1gvnrmdkW0Meta?.name ?? "search___nl___default",
    path: search1gvnrmdkW0Meta?.path ?? "/zoeken",
    meta: search1gvnrmdkW0Meta || {},
    alias: search1gvnrmdkW0Meta?.alias || [],
    redirect: search1gvnrmdkW0Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search1gvnrmdkW0Meta?.name ?? "search___nl",
    path: search1gvnrmdkW0Meta?.path ?? "/zoeken",
    meta: search1gvnrmdkW0Meta || {},
    alias: search1gvnrmdkW0Meta?.alias || [],
    redirect: search1gvnrmdkW0Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search1gvnrmdkW0Meta?.name ?? "search___en",
    path: search1gvnrmdkW0Meta?.path ?? "/search",
    meta: search1gvnrmdkW0Meta || {},
    alias: search1gvnrmdkW0Meta?.alias || [],
    redirect: search1gvnrmdkW0Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search1gvnrmdkW0Meta?.name ?? "search___de",
    path: search1gvnrmdkW0Meta?.path ?? "/s%C3%BCchen",
    meta: search1gvnrmdkW0Meta || {},
    alias: search1gvnrmdkW0Meta?.alias || [],
    redirect: search1gvnrmdkW0Meta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NKcs8HqdOJMeta?.name ?? "vacancies-slug___nl___default",
    path: _91slug_93NKcs8HqdOJMeta?.path ?? "/vacancies/:slug()",
    meta: _91slug_93NKcs8HqdOJMeta || {},
    alias: _91slug_93NKcs8HqdOJMeta?.alias || [],
    redirect: _91slug_93NKcs8HqdOJMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/vacancies/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NKcs8HqdOJMeta?.name ?? "vacancies-slug___nl",
    path: _91slug_93NKcs8HqdOJMeta?.path ?? "/vacancies/:slug()",
    meta: _91slug_93NKcs8HqdOJMeta || {},
    alias: _91slug_93NKcs8HqdOJMeta?.alias || [],
    redirect: _91slug_93NKcs8HqdOJMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/vacancies/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NKcs8HqdOJMeta?.name ?? "vacancies-slug___en",
    path: _91slug_93NKcs8HqdOJMeta?.path ?? "/vacancies/:slug()",
    meta: _91slug_93NKcs8HqdOJMeta || {},
    alias: _91slug_93NKcs8HqdOJMeta?.alias || [],
    redirect: _91slug_93NKcs8HqdOJMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/vacancies/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NKcs8HqdOJMeta?.name ?? "vacancies-slug___de",
    path: _91slug_93NKcs8HqdOJMeta?.path ?? "/vacancies/:slug()",
    meta: _91slug_93NKcs8HqdOJMeta || {},
    alias: _91slug_93NKcs8HqdOJMeta?.alias || [],
    redirect: _91slug_93NKcs8HqdOJMeta?.redirect,
    component: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/pages/vacancies/[slug].vue").then(m => m.default || m)
  }
]